


import { queryAgentInfo, queryAppList, updateMode, updateShelf } from "@/service/apiService";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class UserView extends Vue {
  private agentInfo: any = {
    totalUserNum: 0,
    totalDeviceNum:  0,
    memberCount:  0,
    leftCount: 0,
    invitationCode: '',
  }

  private appPageList: Array<any> = []

  private appQueryParm = {
    userId: 0,
    keywords: '',
    page: 1,
    size: 20
  }

  private mounted() {
    if (this.$route.query.userId) {
      this.appQueryParm.userId = Number(this.$route.query.userId)
    } else {
      this.appQueryParm.userId = 0
    }
    this.getAgentInfo()
    this.getAppList()
  }

  private  changeMode(row: any, value: number) {
    updateMode( row.id, value )
      .then(rep => {
        this.getAppList()
      }).catch(() => {})
  }
  private changeShelf(row: any, value: boolean) {
    updateShelf( row.id, value )
      .then(rep => {
        this.getAppList()
      }).catch(() => {})
  }

  private clickCopy(val: string) {
    const oInput = document.createElement('input')
    oInput.value = val
    document.body.appendChild(oInput)
    oInput.select() // 选择对象;
    console.log(oInput.value)
    document.execCommand('Copy') // 执行浏览器复制命令
    this.$message({
      message: '复制成功',
      type: 'success'
    })
    oInput.remove()
  }

  private handleSearch() {
    this.appQueryParm.page = 1
    this.getAppList()
  }

  private handleSizeChange(val: number) {
    this.appQueryParm.size = val
    this.getAppList()
  }

  private handleCurrentChange(val: number) {
    this.appQueryParm.page = val
    this.getAppList()
  }

  private getAppList() {
    queryAppList(this.appQueryParm.userId, this.appQueryParm.keywords, this.appQueryParm.page, this.appQueryParm.size)
      .then(rep => {
        this.appPageList = rep.data.list
      }).catch(() => {})
  }

  private getAgentInfo() {
    queryAgentInfo().then(rep => {
      this.agentInfo = rep.data
    }).catch(() => {})
  }

}
